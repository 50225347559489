// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

@import '~bootstrap/dist/css/bootstrap.min.css';

@import '~ngx-toastr/toastr.css';


#toast-container > div {
    opacity:1;
  }

  .card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
     background-color: #ffffff; 
    border-bottom: 1px solid #c8ced3;
  }
  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: #fff;
    background: #194972;
}
.sidebar .nav-link.active {
  color: #fff;
  background: #3f87be;
}
.sidebar .nav-title {
  padding: 0.75rem 1rem;
  font-size: 80%;
  font-weight: 700;
  color: #e4e7ea;
  text-transform: uppercase;
  border-bottom: 1px solid #606d82;
}